import axios from 'axios'

const getAPI = axios.create({
    //baseURL: 'http://127.0.0.1:8000',
    baseURL: 'https://api.asap.h-br.com/dsc',
    
    timeout: 60000,
    headers: {
        "Content-Type": "application/json",
    
      },
})

export { getAPI }