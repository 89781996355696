<template>
  <v-app v-if="this.$store.getters.isProjectValid"  id="inspire">

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="#aa0c18"
      dark
       
    >
<!--      <v-app-bar-nav-icon @click.stop="drawer = !drawer" /
<v-toolbar-title > <v-img ... :aspect-ratio="aspectRatio"

> 

style="width: 350px"
-->
      <v-toolbar-title
        
      >
        <a href="/" class="white--text" style="text-decoration: none">
        <v-img
      
      :width="180"
      src="../assets/logo.png"
      class="mt-3"
      cover
    ></v-img></a>
      </v-toolbar-title>
      <!-- <v-text-field
        v-model="searchText"
        flat
        solo-inverted
        hide-details
        prepend-inner-icon="mdi-magnify"
        label="Buscar um descontão"
        class="hidden-sm-and-down pl-10 ml-4"
        @keyup.enter.native="searchOnEnter" 
      /> -->
      <v-spacer />
      <v-autocomplete
        v-model="searchText"
        flat
        solo-inverted
        dense        
        hide-details
        :search-input.sync="searchProduct"
        prepend-inner-icon="mdi-magnify"
        label="Buscar um descontão"
        :items = "productList"
        item-text="key"
        item-value="id"
        hide-no-data
        append-icon=""
        clearable
        return-object
        @keyup.enter.native="searchOnEnter" 
        class="hidden-xs-only ma-auto pa-auto"
        @change ="searchOnClick"
        :menu-props="{closeOnContentClick:true}"
      />
      <v-spacer />
      <div style = " line-height:14px; word-break: break-word; display:inline-block;"   >
        <router-link to="/auth" class="white--text no-underline d-flex text-wrap" justify-right>

       <span class="white--text pr-0 mr-0 justify-right" style="text-decoration: none; font-size:12px; width: 50px" v-if="!this.$store.getters.loggedIn" >
        Bem vindo!
       </span>
       <span 
        class="white--text float-right" 
        style="text-decoration: none; font-size:12px; width: 50px" 
        v-if="this.$store.getters.loggedIn" 
        d-flex justify-end
        >
        Olá, {{this.$store.getters.userNick}} 
       </span>
       <v-icon ml-0 pl-0 start="true">mdi-account-circle</v-icon>     
        </router-link> 
       
<!-- 
       <a href="/auth" class="white--text" style="text-decoration: none; font-size:14px; display:inline-block; width:100%">

       <span class="white--text pa-0" style="text-decoration: none; font-size:12px;" v-if="!this.$store.getters.loggedIn" >
        Entre
       </span>
       <span 
        class="white--text pa-0" 
        style="text-decoration: none; font-size:12px;" 
        v-if="this.$store.getters.loggedIn" 
        d-flex justify-end
        >
        Olá, {{this.$store.getters.userNick}} 
       </span>

       
       </a>
 -->       
      </div>
<!-- 
      <v-btn to="/auth"  class= "pl-0 ml-0" icon >
        
        <v-icon>mdi-account-circle</v-icon>
      </v-btn> -->
      <!-- <v-btn  icon>
        <v-badge
          content="2"
          value="2"
          color="black"
          overlap
        >
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn> -->

      <v-btn   to="/cart" icon>
      <!-- :content="this.$store.getters.cartQtyTotalItems" -->
        <v-badge
          :content="this.$store.getters.cartQtyTotalItems"
          :value="this.$store.getters.cartQtyTotalItems > 0"
          color="black"
          overlap
          
        >
          <v-icon>mdi-cart</v-icon>
        </v-badge>
      </v-btn>
      <template v-slot:extension  v-if="$vuetify.breakpoint.xsOnly">             
      <v-autocomplete
        v-model="searchText"
        flat
        solo-inverted
        dense        
        hide-details
        :search-input.sync="searchProduct"
        prepend-inner-icon="mdi-magnify"
        label="Buscar um descontão"
        :items = "productList"
        item-text="key"
        item-value="id"
        hide-no-data
        append-icon=""
        clearable
        return-object
        @keyup.enter.native="searchOnEnter" 
        
        @change ="searchOnClick"
        :menu-props="{closeOnContentClick:true}"
      />
      </template>
    </v-app-bar>
    <v-main>

      <v-bottom-navigation
        :value="activeBtn"
        color="#aa0c18"
        horizontal
      >
        <!-- <a to="/" class="v-btn">
          <span>Home</span>
        </a> -->
        <router-link to="/" class="v-btn no-underline">
            Home
        </router-link>        

        <v-menu open-on-hover offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on">
              <span>Comprar</span>
            </v-btn>
          </template>
          <v-card
            class="mx-auto"
            max-width="344"
            outlined
          >
            <template>
              <v-treeview 
                v-model="treeModel"
                :items="items" 
                item-text="name"
                item-value="id"
                ref="shopTreeview"
                
                
                :selected-color="'#fff'" 
                activatable 
                open-on-click 
                return-object
                dense
                :open-all="expanded"
                

                >
                
                <template v-slot:label="{ item }">
                  <a @click="treeViewOnClick(item)">{{ item.name }}</a>
                </template>
                </v-treeview>
                          
            </template>          
<!--             <v-list>
                <template v-for='node in items' >
                    <v-list-group v-if='node.children && node.children.length' v-model='node.active' :key = "node.id">
                        <v-list-tile @click='stop' slot='activator'>
                            <v-list-tile-title>{{node.name}}</v-list-tile-title>
                        </v-list-tile>
                        <node-list class='py-0 pl-3' :items='node.children'/>
                    </v-list-group>
                    <v-list-tile @click='stop' :key = "node.id" v-else >
                        <v-list-tile-title>{{node.name}}</v-list-tile-title>
                    </v-list-tile>
                </template>
            </v-list>
 --><!--             <v-list-item
              v-for="(item, index) in items"
              :key="index"
              
              href="/shop"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
 -->
          </v-card>
        </v-menu>
        
<!--         <a href="/product" class="v-btn">
          <span>Product</span>
        </a>
 -->    

        <a href="https://api.whatsapp.com/send?1=pt_BR&phone=554130438200"  target="_blank" class="v-btn pl-1">
        <v-icon size="16px">mdi-whatsapp</v-icon>
        
        <span class="pl-1 ">Comprar pelo whats</span>

        </a> 

        <v-btn to="/blog">
          <span>Blog</span>
        </v-btn>

      </v-bottom-navigation>
    </v-main>
      <router-view/>
      <Reconnecting></Reconnecting>
      <loader></loader>

    <v-footer
      :padless="true"
    >
      <v-card
        flat
        tile
        width="100%"
        class=" white--text text-center"
        color="#aa0c18"
      >
        <v-card-text>
          <v-btn
            class="mx-4 white--text"
            icon
            to="/"
          >
            <v-icon size="24px">mdi-home</v-icon>
          </v-btn>
          <v-btn
            class="mx-4 white--text"
            icon
            to="/contato"
          >
            <v-icon size="24px">mdi-email</v-icon>
          </v-btn>

          <a href="https://www.facebook.com/oficialfarmaciasdescontao/"  target="_blank" class="white--text no-underline">
            <v-icon size="24px"
            class="mx-4 white--text"
            >mdi-facebook</v-icon>
          </a> 
<!-- 
          <v-btn
            class="mx-4 white--text"
            icon
          >
            <v-icon size="24px">mdi-facebook</v-icon>
          </v-btn> 
          
           <v-btn
            class="mx-4 white--text"
            icon
          >
            <v-icon size="24px">mdi-instagram</v-icon>
          </v-btn>
          -->
          <a href="https://www.instagram.com/oficialfarmaciasdescontao/"  
            target="_blank" class="white--text no-underline">
            <v-icon size="24px"
            class="mx-4 white--text"
            >mdi-instagram</v-icon>
          </a>

        </v-card-text>

        <v-card-text class="white--text pt-0">
          <!-- Phasellus feugiat arcu sapien, et iaculis ipsum elementum sit amet. Mauris cursus commodo interdum. Praesent ut risus eget metus luctus accumsan id ultrices nunc. Sed at orci sed massa consectetur dignissim a sit amet dui. Duis commodo vitae velit et faucibus. Morbi vehicula lacinia malesuada. Nulla placerat augue vel ipsum ultrices, cursus iaculis dui sollicitudin. Vestibulum eu ipsum vel diam elementum tempor vel ut orci. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
 -->

     <!-- <v-container>
      <v-layout row wrap>
        <v-flex xs12 sm6>
          <div class="headline white--text">Company Name</div>
          <div class="white--text">1234 Main St.</div>
          <div class="white--text">Anytown, USA 12345</div>
        </v-flex>
        <v-flex xs12 sm6>
          <div class="headline white--text">Contact Us</div>
          <div class="white--text">Email: info@company.com</div>
          <div class="white--text">Phone: 555-555-1234</div>
        </v-flex>
      </v-layout>
    </v-container>
 -->

<!--      <v-container>
      <v-layout row wrap>
        <v-flex xs12 sm3>
          <div class="headline white--text">About Us</div>
          <div class="white--text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
        </v-flex>
        <v-flex xs12 sm3>
          <div class="headline white--text">Products</div>
          <div class="white--text">Product 1</div>
          <div class="white--text">Product 2</div>
          <div class="white--text">Product 3</div>
        </v-flex>
        <v-flex xs12 sm3>
          <div class="headline white--text">Contact Us</div>
          <div class="white--text">Email: info@company.com</div>
          <div class="white--text">Phone: 555-555-1234</div>
        </v-flex>
        <v-flex xs12 sm3>
          <div class="headline white--text">Follow Us</div>
          <div class="white--text">Facebook</div>
          <div class="white--text">Twitter</div>
          <div class="white--text">Instagram</div>
        </v-flex>
      </v-layout>
    </v-container>



 -->        
 <v-container>
      <v-layout row wrap>
        <v-flex xs12 sm3>
          <!-- <div class="headline white--text">About Us</div>
          <div class="white--text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
         -->
         <v-img
      
      :width="300"
      src="../assets/logo.png"
      contain class="align-self-start"
      
    ></v-img>

        </v-flex>
        <v-flex xs12 sm3>
          <div class="headline white--text">Institucional</div>
          <br>
          <div class="pa-1">
          <router-link to="/" class="white--text no-underline">
            Home
          </router-link>
          </div>

          <div class="pa-1">
          <router-link to="/descontao" class="white--text no-underline pa-3">
            A Descontão
          </router-link>
          </div>

          
          <!-- <div class="white--text">Lojas / Unidades</div> -->
          <div class="pa-1">
          <router-link to="/lojas" class="white--text no-underline">
            Lojas / Unidades
          </router-link>
          </div>
          
          <!-- <div class="white--text">Trabalhe Conosco</div> -->
          <div class="pa-1">
          <router-link to="/trabalhe-conosco" class="white--text no-underline">
            Trabalhe Conosco
          </router-link>
          </div>

          <div class="pa-1">
          <router-link to="/blog" class="white--text no-underline">
            Blog
          </router-link>
          </div>
           
        </v-flex>

        <v-flex xs12 sm3>
          <div class="text-left">
          <div class="headline white--text">Nossos Canais</div>
          <br>
            <div class="pa-1">
            <a href="https://api.whatsapp.com/send?1=pt_BR&phone=554130438200"  target="_blank" class="white--text no-underline">
              <v-icon size="16px" color="white">mdi-whatsapp</v-icon>
          
              <span class="pl-1">Compre pelo whatsapp</span>
          
            </a> 
            </div>
            <div class="pa-1">
            <router-link to="/lojas" class="white--text no-underline">
              <v-icon size="18px" color="white">mdi-map-marker-outline</v-icon>
              Lojas
            </router-link>
            </div>
            <div class="white--text pa-1">
              <v-icon size="16px" color="white">mdi-headset</v-icon>
          
              <span class="pl-1">Televendas (41) 3043-8200</span>
            </div>
            <div class="pa-1">
            <router-link to="/contato" class="white--text no-underline">
              <v-icon size="18px" color="white">mdi-email</v-icon>
              Contato
            </router-link>
            </div>            

            <div class="white--text pa-1">
              <a href="http://colaboradores.farmaciasdescontao.com.br/login" target="_blank" class="white--text no-underline"> 
                <v-icon size="16px" color="white">mdi-account-multiple</v-icon>
                <span class="pl-1">Área restrita colaboradores</span>
              </a>
            </div>
              
          </div>
          <!-- <div class="white--text">Email: info@company.com</div>
          <div class="white--text">Phone: 555-555-1234</div> -->
        </v-flex>

        <v-flex xs12 sm3>
          <div class="headline white--text">Receba nossas ofertas</div>
          <br>
          <v-form v-model="valid">
            <v-text-field
              label="Email"
              required
              v-model="email"
              :rules="emailRules"
              outlined
              prepend-inner-icon="mdi-email"
              color="white"
            ></v-text-field>
            <v-btn
              color ="#B71C1C"    
              class="white--text"

              
            >
              Cadastrar
            </v-btn>
            <!--:disabled="!valid" -->
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
 
 
 
      </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Farmácias Descontão</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>
<script>
    import Loader from './Loader';
    import Reconnecting from './Reconnecting';

    import { getAPI } from '../../axios-api'
    import router from '../router'
    export default {
        components: {
          Loader, Reconnecting
        },

        data () {
            return {
            expanded : false,
            productSearchQuery: '',
            productList : [],
            valid : false,
             email: '',
              emailRules: [
                v => !!v || 'é necessário informar o email',
                v => /.+@.+\..+/.test(v) || 'Email precisa ser válido',
              ],
            searchText : '',
            treeActive: [1],
            treeModel: [{id:1, name:null}],
            items: [],
            /*
                {
                    id: 2,
                    name: 'Shoes',
                    active: false,
                    children: [
                        { id: 2, name: 'Casuals' },
                        { id: 3, name: 'Formals' },
                        { id: 4, name: 'Sneakers' },
                    ],
                },
                {
                    id: 1,
                    name: 'Clothing',
                    active: false,
                    children: [
                        { id: 5, name: 'Shirts' },
                        { id: 6, name: 'Tops' },
                        { id: 7, name: 'Tunics' },
                        { id: 8, name: 'Bodysuit' },
                    ],
                }
            ],*/

                /*
                items: [
                    { title: 'T-Shirts' },
                    { title: 'Jackets' },
                    { title: 'Shirts' },
                    { title: 'Jeans' },
                    { title: 'Shoes' },
                ],
                */
                activeBtn: 1,
            }
        },
        created: function () {
            this.getCategories();

            
        }, 

        computed: {
          searchProduct: {
            get () {
              return this.productSearchQuery 
            },
            
            set (searchInput) {
              if (searchInput && this.productSearchQuery  !== searchInput) {
                this.productSearchQuery  = searchInput
                this.getProducts( 1, searchInput) 
              }
            }
          }
        },    


        methods: {
          searchOnClick(e) { 
              

              if (e ) {
                if (e.id ===-1) {
                  
                  this.searchOnEnter();      
                }
                else {
                  
                  router.push(`/product/${e.id}`).catch(err => {
                    console.log('layout.searchOnClick() exception: ', err)

                  })
                                  
                }
                
                this.searchText = null
                this.productSearchQuery = null
                this.productList = []
                document.activeElement.blur();
                
              }
          },
          searchOnEnter() {
            
            this.$store.dispatch('updateProductSearchCategory', null)

            this.$store.dispatch('updateProductSearchDescription', this.productSearchQuery)

            if (this.$route.path !== '/shop') { 
              router.push({ 
                name:'Shop'
                //params:{ 'category': item }
              }).catch(err => {
                console.log('layout.treeViewOnClik() exception: ', err)

              })
            }            
            this.searchText = null
            this.productSearchQuery = null
            this.productList = []
            document.activeElement.blur();
          },
          getProducts(page=1, searchQuery) {
              let parameters = `page=${page}`
              if(searchQuery) {
                  parameters = parameters + '&' + `description=${searchQuery}`
              }
              // &description=${category}
              getAPI.get(`/api/produtos/?${parameters}`, {'silentMode': 1})
              .then(response => {
                
                this.productList = [];
                this.productList.push(
                {
                    id:-1,
                    key:`Mostrar todos com '${searchQuery}'`,
                }
                )
                
                response.data.results.forEach(product => {
                   
                    this.productList.push(
                    {
                        id:product.id,
                        key:product.descricao,
                    }
                    )
                });
                


              })
              .catch(err => {
                  console.log('shop.getProducts() exception: ', err)
              })

          },

          onHandle() {
            console.log('v-on')
          },
          treeViewOnClick(item) {
          
            
            this.searchText = '';
            this.$store.dispatch('updateProductSearchDescription', null)

            this.$store.dispatch('updateProductSearchCategory', item)
            this.expanded = false;
            this.$refs.shopTreeview.updateAll(this.expanded)

            if (this.$route.path !== '/shop') { 
              router.push({ 
                name:'Shop'
                //params:{ 'category': item }
              }).catch(err => {
                console.log('layout.treeViewOnClik() exception: ', err)

              })
;
            }
          },
          getCategories() {
              getAPI.get(`/api/categorias/`)
              .then(response => {
                /*
                {
                        "id": 1,
                        "descricao": "Beleza/Acessório feminino/Brinco",
                        "descricao_ascii": "beleza/acessorio-feminino/brinco"
                    },
                */

                // console.log('response.data:', response.data);
                this.items = [];
                let self = this;
                let vars = {id:0, tree: '', level: 0, treeArray: []};
                response.data.forEach( (item) => {
                  let strKeys = item.descricao

                  if(strKeys.toLowerCase()!=='sem categoria') {
                    this.makeChildren(strKeys.split('/'), this.items, vars)

                    vars.level=0;
                    vars.treeArray= []                  
                  }
                }, self) ;

                
              })
              .catch(err => {
                  console.log('shop.getCategories() exception: ', err)
              })

          },

          makeChildren(keys, parent, vars) {
            let key = ''
            if(keys.length) {
                  key = keys[0];
                  keys.shift();
                  vars.treeArray[vars.level] = key;

                  let newChild = true;
                  let child = parent.find( (current) => {

                      if (current.name === key) {
                        newChild = false
                          return true
                      }
                  })
              
                  if (newChild) {
                      vars.id++;
                      child = {
                          id: vars.id,
                          name: key,
                          tree: vars.treeArray.join('/'), 
                          level: vars.level,

                          children : []
                      }
                  }

                  vars.level++;
                  this.makeChildren(keys, child.children, vars)
                  if (newChild) {
                    parent.push(child)
                  }

              } // if(keys.length)
          },

        } //methods
    }
</script>
<style>
.no-underline {
  text-decoration: none;
}
.no-underline:hover {
  text-decoration: none;
}

/*
.theme--light.v-text-field>.v-input__control>.v-input__slot:before {
    border-color: #E0E0E0;
}

.theme--light.v-label {
    color: #E0E0E0;
}

.theme--light.v-input:not(.v-input--is-disabled) input, .theme--light.v-input:not(.v-input--is-disabled) textarea {
    color: #E0E0E0;
}
*/
</style>