import Vue from 'vue'
//import Router from 'vue-router'
import VueRouter from 'vue-router'
import Home from '@/components/Home'
import Shop from '@/components/Shop'
import Product from '@/components/Product'
import Blog from '@/components/Blog'
import Post from '@/components/Post'
import Cart from '@/components/Cart'
import Layout from '@/components/Layout'

import Descontao from '@/components/Descontao'
import Contato from '@/components/Contato'
import TrabalheConosco from '@/components/TrabalheConosco'
import Lojas from '@/components/Lojas'
import authPage from '../views/auth-page.vue'
import CheckProject from '../views/CheckProject'

//Vue.use(Router)
Vue.use(VueRouter)

  //export default new Router({
  const routes = [
    {
      path: '/check-project',
      name: 'CheckProject',
      component: CheckProject
    },
  
    {
      path: '/',
      component: Layout,
      children:[
        {
          path:'/',
          component:Home,
          name:'Home'
        },
        {
          path:'/shop',
          component:Shop,
          name:'Shop'
        },
        {
          path:'/product/:productId',
          component:Product,
          name:'Product'
        },
        {
          path:'/blog',
          component:Blog,
          name:'Blog'
        },
        {
          path:'/post/:postId',
          component:Post,
          name:'Post'
        },
        {
          path:'/cart',
          component:Cart,
          name:'Cart'
        },
        {
          path: '/auth',
          name: 'authPage',
          component: authPage
        },
        
        {
          path: '/descontao',
          name: 'descontao',
          component: Descontao
        },
        {
          path: '/contato',
          name: 'contato',
          component: Contato
        },
        {
          path: '/trabalhe-conosco',
          name: 'trabalheconosco',
          component: TrabalheConosco
        },
        {
          path: '/lojas',
          name: 'lojas',
          component: Lojas
        },

      ],
      scrollBehavior() {
        document.getElementById('inspire').scrollIntoView({ behavior: 'smooth' });
      }
    }
  ]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

