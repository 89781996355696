<template>

        <v-card :height="iFrameHeight"  class="ma-0 pa-0 noscroll" hide-overlay>


                <iframe src="lojas.html" 
                type="text/html"
                id = 'ilojas'
                width = "100%"
                height = "100%"

                seamless="true"
                >
                </iframe>
                
        </v-card>
<!-- :onload="calcHeight()"
 -->
</template>
<script>

export default {
        created(){
                //this.alert = `document.getElementById('ilojas').contents().find('body').append("<link href="//www.farmaciasdescontao.com.br/assets/template/lib/bootstrap/bootstrap.min.css" rel="stylesheet" type="text/css">");`
        },

        data() {
                return {
                     //   alert : null,
                     iFrameHeight : "1900px"
                }
        },
        methods: {
                calcHeight() {
                        console.log('calcHeight()')
                        //find the height of the internal page
                        let the_height = document.getElementById('ilojas').contentWindow.document.body.scrollHeight;

                        //change the height of the iframe
                        document.getElementById('ilojas').height=the_height;
                        
                        alert('This iframe should be ' + the_height + ' pixels in height to avoid scrolling.');
                }
        },
}
</script>


<style >
/*
.noscroll /deep/{
   overflow: hidden;
}
*/
    
        * { margin:0px; }
        body {overflow:hidden;}
    
</style>