import Vue from 'vue'
import Vuex from 'vuex'
import { getAPI } from '../axios-api'
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    accessToken: null,
    refreshToken: null,
    isProjectValid : false,
    userNick : null,
    globalLoading : false,
    globalReconnecting : false,

    productSearch : {
      category  :null,
      description : null
    },

    cart : {

      shippingFee : "12.50",

      items : [

      ]
    },

    auth :  {
      currentSignUpStep: 1,
      isSignInPanelActive: false,

      formData: {
          username: null,
          password: null,
          firstName: null,
          lastName: null,
          email: null,
          address: null,
          phoneNumber: null,
      },
    },
  

  },
  

  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, { expires: 3, secure: true }),
        removeItem: (key) => Cookies.remove(key)
      }
    })
  ],

  mutations: {
    // auth
    setIsSignInPanelActive(state, newVal) {
      state.auth.isSignInPanelActive = newVal;
    },
    setCurrentSignUpStep(state, newVal) {
        state.auth.currentSignUpStep = newVal;
    },
    setFormData(state, newVal) {
        state.auth.formData = {...state.formData, ...newVal };
    },
    // auth

    updateStorage (state, { access, refresh }) {
      state.accessToken = access
      state.refreshToken = refresh
    },
    destroyToken (state) {
      state.accessToken = null
      state.refreshToken = null
    },

    userNick(state, nick) {
      state.userNick = nick;
    },

    updateProductSearchCategory (state, category) {
      state.productSearch.category = category
    },

    updateProductSearchDescription (state, description) {
      state.productSearch.description = description
    },

    cartAddItem(state, item) {
      state.cart.items.push(item)
    },

    cartRemoveItem(state, id) {
      const itemIndex = state.cart.items.findIndex(cartItem => cartItem.id === id);
      state.cart.items.splice(itemIndex, 1);
    },

    cartSetItemQuantity(state, { id, quantity }) {
      const NOT_FOUND = -1;
      const itemIndex = state.cart.items.findIndex(cartItem => cartItem.id == id);
      if(itemIndex > NOT_FOUND) {
          state.cart.items[itemIndex].quantity = quantity;
      }
      else {
          console.log('store setQuantity exception: id ', id, ' not found')
      }
      
    },

    loaderToggle (state, status) {
      state.globalLoading = status
    },
    reconnectToggle (state, status) {
      state.globalReconnecting = status
    },
    setProjectAsValid (state, status) {
      state.isProjectValid = status
    }

    
  },

  getters: {
    isProjectValid (state) {
      return state.isProjectValid 
    },

    // auth
    getIsSignInPanelActive: (state) => {
      return state.auth.isSignInPanelActive;
    },
    getCurrentSignUpStep: (state) => {
        return state.auth.currentSignUpStep;
    },
    getFormData: (state) => {
        return state.auth.formData;
    },
  
    // auth
    loggedIn (state) {
      return state.accessToken != null
    },
    userNick (state) {
      return state.userNick
    },    

    productSearch (state) {
      return state.productSearch
    },

    cart (state) {
      return state.cart
    },
    
    getCartItemById: (state) => (id) => {
      return state.cart.items.find(item => item.id === id)
    },  

    getShippingFee (state)  {
      console.log('state.cart.shippingFee):', state.cart.shippingFee)
      return parseFloat(state.cart.shippingFee);
    },  
    
    // parseInt because localStorage converts everything to string
    cartQtyTotalItems: state =>
      state.cart.items.reduce((total, product) => {
        return total + parseInt( product.quantity) ;
      }, 0),

      cartOrderTotal: state =>
      state.cart.items.reduce((total, product) => {
        return total + ( parseInt( product.quantity) * parseFloat(product.price) );
      }, 0),

  }, // getters

  actions: {

    userLogout (context) {
      if (context.getters.loggedIn) {
          context.commit('destroyToken')
      }
    },
    updateToken (context, newtoken) {
      context.commit('updateStorage', { access: newtoken.access, refresh: newtoken.refresh }) 
    },
    updateProductSearchCategory (context, category) {
      context.commit('updateProductSearchCategory', category) 
    },
    updateProductSearchDescription (context, description) {
      context.commit('updateProductSearchDescription', description) 
    },
    
    cartRemoveItem (context, id) {
      context.commit('cartRemoveItem', id) 
    },

    cartSetItemQuantity (context,  payload) {
      
      context.commit('cartSetItemQuantity', { id: payload.id, quantity: payload.quantity }) 
    },

    cartAddItem (context, item) {
      context.commit('cartAddItem', item) 
    },


    userLogin (context, usercredentials) {
      return new Promise((resolve, reject) => {

        getAPI.post('/api/token/', {
          username: usercredentials.username,
          password: usercredentials.password
        })
          .then(response => {
            context.commit('updateStorage', { access: response.data.access, refresh: response.data.refresh }) 
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })

    },

    
    userNick (context) {
      return new Promise((resolve, reject) => {
        getAPI.get('/api/get-user/')
          .then(response => {
            let nick = response.data.full_name.split(' ')[0]
            context.commit('userNick', nick) 
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    /*
    getPrestadores(context) {
      return new Promise((resolve, reject) => {
        getAPI.get('/api/prestadores/')
          .then(response => {
            context.commit('updatePrestadorList', response.data) 
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })      
    },
    */
    loader (context, status) {
      context.commit('loaderToggle', status) 
    },
    reconnect (context, status) {
      context.commit('reconnectToggle', status) 
    },
    setProjectAsValid (context) {
      context.commit('setProjectAsValid', true) 
    },

  }, // actions

})