<template>
      <div class="row pt-5 pb-5 divCenter">
        <div class="col-sm-4 col-xs-3 col-md-6">
          <v-card outlined>
          <v-tabs >
            <v-tab v-if="!this.$store.getters.loggedIn">Entrar</v-tab>

            <v-tab >{{this.$store.getters.loggedIn ? 'Minha Conta' : 'Criar uma Conta'}}</v-tab>

            <!-- <v-tab>REVIEWS</v-tab> -->
            <v-tab-item v-if="!this.$store.getters.loggedIn">
              <SignInForm />
            </v-tab-item>
            <v-tab-item>
                <SignUpContinuationForm />
            </v-tab-item>


          </v-tabs>
          </v-card>
        </div>
        
      </div>

</template>

<script>
import SignInForm from "../components/auth-page/sign-in-form.vue";

import SignUpContinuationForm from "../components/auth-page/sign-up-continuation-form.vue";
export default {
  components: {
    SignInForm,
    SignUpContinuationForm,
  },
  computed: {
    isSignInPanelActive: {
      get: function () {
        return this.$store.getters["getIsSignInPanelActive"];
      },
      set: function (newVal) {
        this.$store.commit("setIsSignInPanelActive", newVal);
      },
    },
    currentSignUpStep: {
      get: function () {
        return this.$store.getters["getCurrentSignUpStep"];
      },
      set: function (newVal) {
        this.$store.commit("setCurrentSignUpStep", newVal);
      },
    },
  },
};
</script>

<style scoped>

.divCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>