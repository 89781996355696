<template>
  <div>
    <v-container>

      <p class="display-1 font-weight-light	text-center pa-4">Carrinho de Compras</p>
      <v-row>
        <v-col :cols="12" md="9" sm="12" >
          <v-simple-table
          height = "430" 
          fixed-header
          dense   
          >
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left pl-9">ITEM</th>
                <th class="text-left">PREÇO</th>
                <th class="text-left">QUANTIDADE</th>
                <th class="text-center">TOTAL</th>
                <th class="text-center"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in cartItems" :key="item.id" >
                <td class="maxSize100pxOrEllipsesTD">
                  <v-list-item
                  :key="item.id"
                  
                >
                  <v-list-item-avatar>
                    <!-- "require(`../assets/img/shop/${item.imageName}`)"-->
                    <v-img :src= "checkImagePath(item.imageName)"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title >
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span  v-bind="attrs" v-on="on">{{ item.product }}</span>
                        </template>
                        <span>{{ item.product }}</span>
                        </v-tooltip>                       
                      
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ item.categoryTree }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item></td>
                <td>R${{ item.price }}</td>
                <td>
                  <!-- :value="item.quantity"-->
                  <v-text-field
                    v-model = "item.quantity"
                    class="pt-10"
                    label=""
                    style="width: 80px;"
                    single-line
                    outlined

                    @change="quantityOnChange(item.id, item.quantity)"
                    @blur = "quantityOnChange(item.id, item.quantity)"                

                    
                    type="number"
                  ></v-text-field>
                </td>
                <td>R${{ parseInt(item.quantity) * parseFloat(item.price) }}</td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">  
                        <v-btn
                           
                            color="red"
                            class="ma-0 white--text"
                            fab
                             text
                             small
                             v-bind="attrs"
                             v-on="on"
                             @click="cartRemoveItem(item.id)"
                            >
                            <v-icon >
                                 mdi-close
                            </v-icon>
                     
                        </v-btn>
                    </template>
                    <span>Remover item</span>
                  </v-tooltip>                  

                </td>
              </tr>

<!--               <tr>
                <td>
                  <v-list-item
                  key="1"
                  
                >
                  <v-list-item-avatar>
                    <v-img :src="require('../assets/img/shop/2.jpg')"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title >Item 2</v-list-item-title>
                    <v-list-item-subtitle>Lorem Ipsum</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item></td>
                <td>R$40.00</td>
                <td>
                  <v-text-field
                    class="pt-10"
                    label="Outlined"
                    style="width: 80px;"
                    single-line
                    outlined
                    value="2"
                    type="number"
                  ></v-text-field>
                </td>
                <td>R$80.00</td>
                <td><a>X</a></td>
              </tr> -->

              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col :cols="12" md="3" sm="12" style="background-color: lightgray">
          <p class="headline text-center Pt-3">RESUMO DO PEDIDO</p>
          <p class="overline">          </p>  <!-- Shipping and additional costs are calculated based on values you have entered.-->
          <br>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
              <tr>
                <td>Total do Pedido</td>
                <td class="text-right" style="width: 50px;">R$&nbsp;{{ $store.getters.cartOrderTotal.toFixed(2).replace('.',',')}}</td>
              </tr>
              <tr>
                <td>Entrega</td>
    
                <td class="text-right" style="width: 50px;">R$&nbsp;{{$store.getters.getShippingFee.toFixed(2).replace('.',',')}}</td>
              </tr>
              <tr>
                <td></td>
                <td class="text-right" style="width: 50px;"></td>
              </tr>
              <tr>
                <td>Total</td>
                <td class="text-right" style="width: 50px;"><b>R$&nbsp;{{($store.getters.cartOrderTotal + $store.getters.getShippingFee).toFixed(2).replace('.',',')}}</b></td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="text-center">
            <v-btn class="primary white--text mt-5" outlined>PAGAMENTO</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- <v-card  class="accent" >
      <v-container>
        <v-row no-gutters>
          <v-col class="col-12 col-md-4 col-sm-12">
            <v-row >
              <v-col class="col-12 col-sm-3 pr-4 hidden-sm-only" align="right">
                <v-icon class="display-2">mdi-truck</v-icon>
              </v-col>
              <v-col class="col-12 col-sm-9 pr-4">
                <h3 class="font-weight-light">FREE SHIPPING & RETURN</h3>
                <p class="font-weight-thin">Free Shipping over $300</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="col-12 col-md-4 col-sm-12">
            <v-row >
              <v-col class="col-12 col-sm-3 pr-4" align="right">
                <v-icon class="display-2">mdi-cash-usd</v-icon>
              </v-col>
              <v-col  class="col-12 col-sm-9 pr-4">
                <h3 class="font-weight-light">MONEY BACK GUARANTEE</h3>
                <p class="font-weight-thin">30 Days Money Back Guarantee</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="col-12 col-md-4 col-sm-12">
            <v-row>
              <v-col class="col-12 col-sm-3 pr-4" align="right">
                <v-icon class="display-2">mdi-headset</v-icon>
              </v-col>
              <v-col  class="col-12 col-sm-9 pr-4">
                <h3 class="font-weight-light">020-800-456-747</h3>
                <p class="font-weight-thin">24/7 Available Support</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card> -->
    <br>
  </div>
</template>
<script>
    export default {
        data: () => ({
            
            items: [
                {
                    id : 1,
                    product: 'Item 1 teste de item grande para quebrar a linha e ferrar com o layout mesmo de verdade gigante',
                    categoryTree: 'Beleza/facial',
                    quantity: 1,
                    price: 10.25,
                    total: 10.25,
                    imageName: '1.jpg'
                },
                {
                    id : 2,
                    product: 'Item 2',
                    categoryTree: 'Beleza/Cabelos',
                    quantity: 1,
                    price: 15.25,
                    total: 15.25,
                    imageName: '2.jpg'
                },
                {
                    id : 3,
                    product: 'Item 3',
                    categoryTree: 'Beleza/Pés',
                    quantity: 1,
                    price: 25.25,
                    total: 25.25,
                    imageName: '2.jpg'
                },
                {
                    id : 2,
                    product: 'Item 4',
                    categoryTree: 'Beleza/Pelo',
                    quantity: 1,
                    price: 15.25,
                    total: 15.25,
                    imageName: '1.jpg'
                },
                {
                    id : 2,
                    product: 'Item 5',
                    categoryTree: 'Beleza/Mãos',
                    quantity: 1,
                    price: 35.25,
                    total: 35.25,
                    imageName: '1.jpg'
                },


            ],
            cartItems : [],

        }),
        created() {
          
          this.cartItems = JSON.parse(JSON.stringify(this.$store.getters.cart.items));
          this.cartItems.forEach( item => {
            if(!item?.quantity){
              item.quantity = 1;
            }

          }
          )
        },
        watch: {

            '$store.getters.cart.items':  {
                handler(newVal /*, oldVal*/) {    
                    if(newVal) {
                        this.cartItems = JSON.parse(JSON.stringify(newVal)); // $store.getters.cart.items
                    }
                    
                },
                deep: true
            },
        },
        methods: {
            checkImagePath(imageName){
                let srcDefault = '/img/placeholder-med-tarja-vermelha_200x200.jpg'   // require(`../assets/img/shop/placeholder-med-tarja-vermelha_200x200.jpg`);  
                let imagePath

                try {
                  imagePath = `/img/${imageName}` //require(`../assets/img/shop/${imageName}`);  
                } catch (error) {

                  imagePath = srcDefault // require(`../assets/img/shop/placeholder-med-tarja-vermelha_200x200.jpg`);  
                }
                
                return imagePath;
            },
            quantityOnChange(id, quantity)          {
                
                if (!quantity || quantity<=0) {
                  quantity = 1
                }
                let item = this.$store.getters.getCartItemById(id) ;
                
                if(item.quantity !== quantity) {
                    
                    this.$store.dispatch('cartSetItemQuantity', {id, quantity})
                }

            },
            cartRemoveItem( id) {
                this.$store.dispatch('cartRemoveItem', id)
            }
        },

    }
</script>


<style scoped>

 .maxSize100pxOrEllipsesTD /deep/   {
 max-width: 300px;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}
</style>