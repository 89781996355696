import { getAPI } from './axios-api';
import router from './src/router'
//import TokenService from "./token.service";
import VueJwtDecode from 'vue-jwt-decode'

const setup = (store) => {

  getAPI.interceptors.request.use(
    async (config) => {
      
      
      if(!config.url.includes("token-refresh") &&
        !config.url.includes("auth") &&
        store.state.accessToken){
        // console.log( 'CHECK TOKEN');  
        var tokenDecoded = null;
        try {
          
          tokenDecoded = VueJwtDecode.decode(store.state.accessToken)
          //console.log('token decode', tokenDecoded.exp*1000 <= time  ? 'expired' : 'valid token', (tokenDecoded.exp*1000) - time)
        }
        catch(err) {
          console.log( 'intercept decode exception:',err.message);
        }
        if (tokenDecoded.exp) {
          //console.log( 'DECODED');
          const time = new Date().getTime();
          if(tokenDecoded.exp*1000 <= time){
            //console.log( 'EXPIRADO', tokenDecoded.exp*1000, time);
            store.dispatch('reconnect', true)
            store.dispatch('loader', false)
            //console.log('store.state.globalReconnecting', store.state.globalReconnecting)
            
            

            try {
              //console.log( 'AWAIT');
              const rs = await getAPI.post("/api/token-refresh/", {
                  refresh: store.state.refreshToken
              });
              
              store.dispatch('updateToken', { access: rs.data.access, refresh: store.state.refreshToken }) 
              //console.log( 'REFRESHED');

            } catch (_error) {
              
              store.dispatch('userLogout') 
              
              router.push({name: 'authPage'}).catch(()=>{});
              
              return Promise.reject(_error);
            }
            finally {
              store.dispatch('reconnect', false)
              //console.log( 'FINISHED');
            }

          }

        }
      }
      const token = store.state.accessToken;
      //
      if (!config.url.includes("token") && 
          !(config.silentMode) ) {
        
        store.dispatch('loader', true)
      }
      else {
        //console.log('loader FALSE')
      }
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        // config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      store.dispatch('loader', false)
      return Promise.reject(error);
    }
  );

  getAPI.interceptors.response.use(
    (res) => {
      store.dispatch('loader', false)
      return res;
    },
    async (err) => {
      store.dispatch('loader', false)
      const originalConfig = err.config;
      

      if(originalConfig.url.includes("auth") ){
        
        return Promise.reject(err);
  
      }
      else if ( originalConfig.url.includes("token-refresh" ) ) {
        
        //store.dispatch('userLogout') 
        //router.push({name: 'login'})
        return Promise.reject(false);
      }
      else if(  ! err.response) {
        
        return Promise.reject(err);
      } else {
        
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            
            const rs = await getAPI.post("/api/token-refresh/", {
                refresh: store.state.refreshToken
            });
            
            store.dispatch('updateToken', { access: rs.data.access, refresh: store.state.refreshToken }) 

            return getAPI(originalConfig);
          } catch (_error) {
            
            store.dispatch('userLogout') 
            
            
            router.push({name: 'authPage'}).catch(()=>{});
            
            return Promise.reject(_error);
          }
        }
      }
      
      return Promise.reject(err);
    }
  );
};

export default setup;