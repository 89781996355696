<template>
  <div>
    <v-container>
      <p class="display-2 font-weight-light	text-center pa-4">{{filteredPost[0].title}}</p>
      <p class="overline text-center">Postada em  {{filteredPost[0].postDate}}</p>
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <v-img
            class="white--text align-end"
            height="400px"
            :src="require('../assets/img/home/' + filteredPost[0].image)"
          ></v-img>
          <p class="display-1 mb-0 pt-5">{{filteredPost[0].subtitle}} </p>

          <p class="subtitle-1 font-weight-light pt-5  " v-html = "filteredPost[0].text"></p>

        </div>
      </div>
    </v-container>
    <br>
    
    
  </div>
</template>
<script>
    export default {
        data: () => ({
            posts : [
                {id: 1, postDate: '29 FEVEREIRO 2022', image: 'sono.jpg', title: 'Saiba como melhorar seu sono de uma vez por todas' ,subtitle : 'Se você não está dormindo bem, você precisa ler isso.',
                text : `
                Uma boa noite de sono &eacute; sin&ocirc;nimo de qualidade de vida, veja algumas dicas para conseguir regular seu sono:<br><br>

-&nbsp; O tempo ideal de sono di&aacute;rio &eacute; de 8 a 9 horas, portanto, &eacute; ideal programar a hora que voc&ecirc; vai dormir e a que precisa acordar para que esse tempo complete as horas necess&aacute;rias.<br><br>

- Manter os aparelhos eletr&ocirc;nicos desligados tamb&eacute;m s&atilde;o uma &oacute;tima dica, visto que eles prendem a aten&ccedil;&atilde;o e agitam&nbsp;prejudicando o seu sono.<br><br>

- Criar um ambiente escuro favorece o sono, pois deixa o ambiente mais aconchegante e estimula o sono.<br><br>

- Pratique exerc&iacute;cios regularmente,&nbsp;pode ser ben&eacute;fico porque&nbsp;ao praticar exerc&iacute;cios&nbsp;o corpo gasta mais energia, aumentando a&nbsp;necessidade de descanso ao longo do dia.<br><br>

- Evite tomar bebidas com cafe&iacute;na pelo menos 6h antes de dormir,&nbsp;porque isso pode deix&aacute;-lo mais desperto&nbsp;e ter dificuldade para adormecer.<br><br>

-&nbsp;O uso de &oacute;leos essenciais como a lavanda, tem um efeito relaxante e calmante, isso por que ao fazer inspira&ccedil;&otilde;es profundas com o &oacute;leo essencial, faz com que o c&eacute;rebro receba mais oxig&ecirc;nio, al&eacute;m de tamb&eacute;m estimular a produ&ccedil;&atilde;o hormonal, promovendo a sensa&ccedil;&atilde;o de bem estar e de relaxamento, favorecendo o sono.
                
`},

               {id: 2, postDate: '17 AGOSTO 2021', image: 'caminhada.jpg', title: 'CAMINHADA, A MELHOR OPÇÃO PARA COMEÇAR AS ATIVIDADES FÍSICAS' ,subtitle : 'Um poderoso exercício que pode ser feito por todas as pessoas.',
                text : `
								Veja a seguir alguns benef&iacute;cios desse poderoso exerc&iacute;cio:&nbsp;<br><br>

-&nbsp;A caminhada ajuda a diminuir o incha&ccedil;o das pernas e do tornozelo, pois auxilia na circula&ccedil;&atilde;o sangu&iacute;nea e diminui a reten&ccedil;&atilde;o dos&nbsp;l&iacute;quidos.<br><br>

-&nbsp; Feitas regularmente, elas&nbsp;ajudam a prevenir algumas doen&ccedil;as como, aterosclerose e hipertens&atilde;o, obesidade, diabetes do tipo 2 e osteoporose. Isso porque os v&aacute;rios m&uacute;sculos s&atilde;o trabalhados durante a atividade f&iacute;sica, gerando maior gasto energ&eacute;tico, al&eacute;m de promover melhora da circula&ccedil;&atilde;o sangu&iacute;nea.<br><br>

- Al&eacute;m do fortalecimento dos m&uacute;sculos, a caminhada &eacute; uma atividade f&iacute;sica que envolve v&aacute;rias partes do corpo, e&nbsp;a sua pr&aacute;tica regular pode ajudar a diminuir dores e a melhorar a postura corporal.<br><br>

-&nbsp;Acredita-se que pr&aacute;tica de exerc&iacute;cios de forma regular tamb&eacute;m promova&nbsp;melhora da mem&oacute;ria, isso porque a atividade f&iacute;sica estimula uma&nbsp;maior circula&ccedil;&atilde;o sangu&iacute;nea no c&eacute;rebro, favorecendo a produ&ccedil;&atilde;o de catecolaminas durante o exerc&iacute;cio.<br><br>                
`},


               {id: 3, postDate: '29 NOVEMBRO 2022', image: 'verao.jpg', title: 'PROJETO VERÃO: JÁ OUVIU FALAR DE PRÉ-BRONZEAMENTO?' ,subtitle : 'Pré-Bronzeamento',
                text : `
								O Pr&eacute;-Bronzeamento s&atilde;o cuidados que voc&ecirc; deve ter antes do bronze e s&atilde;o essenciais para atingir o bronzeado perfeito.<br>

O bronzeamento &eacute; muito mais do que simplesmente deitar-se sob o sol e tostar. Isso porque, para obter a marquinha t&atilde;o sonhada, &eacute; necess&aacute;rio seguir alguns passos que come&ccedil;am&nbsp;antes mesmo do bronze. Por isso, separamos algumas dicas do que voc&ecirc; precisa fazer antes de bronzear-se.<br>

&nbsp;<br>

Esfoliar a pele<br>

Comece o processo de esfolia&ccedil;&atilde;o algumas semanas antes, pois ele &eacute; essencial para remover as c&eacute;lulas mortas da pele e deix&aacute;-la uniforme. Dessa forma, quando for a hora do bronze, a cor tamb&eacute;m ficar&aacute;, consequentemente, mais uniforme. Por&eacute;m, nada de esfoliar a pele todo dia, viu? Duas vezes por semana &eacute; o suficiente, assim, voc&ecirc; d&aacute; um descanso para a pele se regenerar.<br>

&nbsp;<br>

Alimentar-se bem<br><br>

Ter uma dieta balanceada &eacute; essencial para garantir sua sa&uacute;de e est&eacute;tica. Por isso, algumas semanas antes do bronze, comece a introduzir nas refei&ccedil;&otilde;es alimentos que contenham betacaroteno, como cenoura, ab&oacute;bora, mam&atilde;o, manga, damasco e beterraba. Al&eacute;m de serem deliciosos, eles facilitam a produ&ccedil;&atilde;o de melanina, que &eacute; o que d&aacute; a colora&ccedil;&atilde;o mais escura na pele.<br>

&nbsp;<br>

Hidrata&ccedil;&atilde;o<br>

Antes, durante e depois do bronze &eacute; essencial beber pelo menos dois litros de &aacute;gua por dia. Ter a pele hidratada antes de tomar sol faz com que ela n&atilde;o fique ressecada e sens&iacute;vel &agrave;s queimaduras. Os creminhos de hidrata&ccedil;&atilde;o tamb&eacute;m s&atilde;o &oacute;timos aliados, mas nada substitui beber &aacute;gua.<br>

&nbsp;<br>

Usar autobronzeador<br>

Se voc&ecirc; quer ficar bronzeada pra valer, pode investir em um autobronzeador antes de deitar-se sob o sol. Esses produtos possuem uma subst&acirc;ncia chamada DHA, que reage com os amino&aacute;cidos que est&atilde;o na sua pele e resultam em uma nova subst&acirc;ncia que d&aacute; o aspecto de bronzeamento.<br>

&nbsp;<br>

Por&eacute;m, &eacute; essencial lembrar que s&oacute; devemos usar produtos confi&aacute;veis, n&atilde;o acredite em t&eacute;cnicas caseiras, pois al&eacute;m de danificar sua pele, podem afetar sua sa&uacute;de de modo geral. Na Descont&atilde;o voc&ecirc; encontra profissionais preparados para de auxiliar a escolher os melhores produtos.<br><br>
`},
            ],
            rating:4.5,
            item: 5,
            items: [
                {
                    avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
                    title: 'Lorem ipsum dolor?',
                    subtitle: "<span class='text--primary'>Ali Connors</span> &mdash; Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nisl tincidunt eget nullam non. Tincidunt arcu non sodales neque sodales ut etiam. Lectus arcu bibendum at varius vel pharetra. Morbi tristique senectus et netus et malesuada.\n" +
                        "\n",
                },
                {
                    avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
                    title: 'Lorem ipsum dolor <span class="grey--text text--lighten-1">4</span>',
                    subtitle: "<span class='text--primary'>to Alex, Scott, Jennifer</span> &mdash; Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore"
                },
                {
                    avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
                    title: 'Lorem ipsum dolor',
                    subtitle: "<span class='text--primary'>Sandra Adams</span> &mdash; Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                },
                {
                    avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
                    title: 'Lorem ipsum dolor',
                    subtitle: ""
                },
                {
                    avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
                    title: 'Lorem ipsum dolor',
                    subtitle: "<span class='text--primary'>Britta Holt</span> &mdash; Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                },
            ],
        }),

        computed : {
            
            filteredPost : function() {
                return this.posts.filter((record) => {
                    return record.id == this.$route.params.postId;
                })
            },
            
        }        
    }    
</script>
