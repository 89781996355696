<template>
  <div class="pb-5">
    <v-form
      v-model = "validProfile"
      action="#"
      :class="{
        'px-2': $vuetify.breakpoint.smAndDown,
        'px-8': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <h2 :class="{
        'pb-5 pt-5 sm-title': $vuetify.breakpoint.smAndDown,
        'pb-5 pt-5': $vuetify.breakpoint.mdAndUp,
      }" :style="{ color: 'gray' }">Dados Pessoais</h2>

      <v-text-field
        label = "CPF"
        placeholder="CPF"
        :disabled = "this.$store.getters.loggedIn"
        filled
        v-model="user.cpf"
        prepend-inner-icon="mdi-account"
        @change="validateCpf()"
        @blur="validateCpf()"
        :error-messages="cpfErrors"
        :rules="[(v) => !!v || 'Campo necessário']"
        
        v-mask="'###.###.###-##'"
        class = "inputNumberNoSpin"
        
      ></v-text-field>

      <v-text-field
        
        label="Nome completo"
        filled
        v-model="user.full_name"
        prepend-inner-icon="mdi-account"
        :rules="[(v) => !!v || 'Campo necessário']"
      ></v-text-field>

      <v-text-field
        label="Data de Nascimento"
        prepend-inner-icon="mdi-account"
        filled
        v-model="user.birth_date"
        v-mask="'##/##/####'"
        @change="birthDateOnChange(user.birth_date)"
        :error-messages="birthDateErrors"

      ></v-text-field>

<!--       <v-text-field
        label="Sexo"
        prepend-inner-icon="mdi-account"
        filled
        v-model="user.gender"
      ></v-text-field>
 -->      <v-select
        label="Gênero"
        v-model="user.gender"
        filled
        prepend-inner-icon="mdi-account"
        :items = "[ {id: 1, text: 'Masculino'}, {id: 2, text: 'Feminino'}, {id: 3, text: 'Outro'} ]"
        required
        item-text="text"
        item-value="id"
        :menu-props="{ bottom: true, offsetY: true }"         
      ></v-select>

      <v-text-field
        label="Email"
        prepend-inner-icon="mdi-email"
        filled
        :rules="emailRules"
        v-model="user.email"
        oninput="javascript:  this.value = this.value.toLowerCase();"
        
      ></v-text-field>


      <v-text-field
        label="CEP"
        prepend-inner-icon="mdi-map-marker"
        filled
        v-model="user.zip"
        @blur="getZipAddress()"
        required
        :rules="[(v) => !!v || 'Campo necessário']"
        v-mask="'##.###-###'"
      ></v-text-field>

      <v-text-field
        label="Cidade"
        prepend-inner-icon="mdi-map-marker"
        filled
        v-model="user.city"
        disabled
      ></v-text-field>

      <v-text-field
        label="Estado"
        prepend-inner-icon="mdi-map-marker"
        filled
        v-model="user.state"
        disabled
      ></v-text-field>

      <v-text-field
        label="Rua"
        prepend-inner-icon="mdi-map-marker"
        filled
        v-model="user.street"
        :rules="[(v) => !!v || 'Campo necessário']"
      ></v-text-field>

    <v-text-field
        label="N°"
        prepend-inner-icon="mdi-map-marker"
        filled
        v-model="user.number"
        :rules="[(v) => !!v || 'Campo necessário']"
        v-mask="'#####'"
      ></v-text-field>

    <v-text-field
        label="Complemento"
        prepend-inner-icon="mdi-map-marker"
        filled
        v-model="user.complemnt"
      ></v-text-field>

    <v-text-field
        label="Bairro"
        prepend-inner-icon="mdi-map-marker"
        filled
        v-model="user.district"
        :rules="[(v) => !!v || 'Campo necessário']"
      ></v-text-field>

      <v-text-field
        label="Celular"
        filled
        prepend-inner-icon="mdi-cellphone"
        v-model="user.mobile"
        v-mask="'(##) # ####-####'"
        :rules="[(v) => !!v || 'Campo necessário']"

      ></v-text-field>

      <v-text-field
        label="Telefone"
        filled
        prepend-inner-icon="mdi-phone"
        v-model="user.phone"
        v-mask="'(##) ####-####'"

      ></v-text-field>

      <v-switch
          label="Alterar senha"
          v-model="updatePassword"
          dense
          hide-details  
          class="my-0 py-0"
          v-if = "$store.getters.loggedIn"
          @change="updatePasswordOnChange()"
      ></v-switch>

      <v-text-field
        label="Senha"
        filled
        :disabled ="!updatePassword"
        prepend-inner-icon="mdi-lock"
        v-model="user.password"
        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPass ? 'text' : 'password'"
        @click:append="showPass = !showPass"
        :rules="updatePassword ? [(v) => !!v || 'Campo necessário'] : []"
        @change="passwordOnChange(user.password, checkPassword)"
        :error-messages="passwordErrors"

      ></v-text-field>
      <v-text-field
        label="Confirmação senha"
        filled
        :disabled ="!updatePassword"
        prepend-inner-icon="mdi-lock"
        v-model="checkPassword"

        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPass ? 'text' : 'password'"
        @click:append="showPass = !showPass"
        :rules="updatePassword ? [(v) => !!v || 'Campo necessário'] : []"

        @change="passwordOnChange(user.password, checkPassword)"
        :error-messages="passwordErrors"

      ></v-text-field>



      <v-container
      class="py-0"
      fluid
      
      >
      <v-switch
          label="Receber ofertas personalizadas por e-mail"
          v-model="user.email_marketing"
          dense
          hide-details  
          class="my-0 py-0"
      ></v-switch>
      </v-container>

      <v-container
      class="py-0"
      fluid
      >
      <div class="d-inline-flex align-center">
       <v-switch
          label=""
          v-model="user.privacy_terms"
          dense
          hide-details  
          class="my-0 py-0"
      >
      
      </v-switch>

        <span>Li e aceito os <a class="cursor-pointer" onclick="window.open('politica.pdf','_blank','fullscreen=yes'); return false;"> termos de uso de privacidade</a></span>
      </div>      
      
      </v-container>          

      <v-container
      class="py-0"
      fluid
      
      >
      <v-switch
          label="CPF na nota fiscal"
          v-model="user.cpf_nf"
          dense
          hide-details  
          class="my-0 py-0"
      ></v-switch>
      </v-container>
      
      <br>
      <v-row>

        <v-col cols="12" md="12"
          ><v-btn
            color="info"
            dark
            tile
            block
            class="pa-6 font-weight-bold"
            elevation="0"
            @click="saveFormData()"
            >Salvar</v-btn
          ></v-col
        >
      </v-row>
    </v-form>
    <br>
   <v-snackbar
      v-model="alertInvalidProfile"
    >
      {{alertMessage}}

        <v-btn
          color="error"
          text
          
          @click="alertInvalidProfile = false"
        >
          Fechar
        </v-btn>

    </v-snackbar>      

  </div>
</template>
<script>
import { getAPI } from '../../../axios-api'
import axios from "axios";
import { checkCpf } from '../../functions.js';

export default {
  data: () => {
    return {
        updatePassword : false, //this.$store.getters.loggedIn ? false : true,
        alertMessage : null,
        alertInvalidProfile : false,
        validProfile : false,
        passwordErrors : [],
        birthDateErrors: [],
        emailRules: [
          v => !!v || 'é necessário informar o email',
          v => /.+@.+\..+/.test(v) || 'Email precisa ser válido',
        ],

        showPass : false,
        cpfErrors : [],
        checkPassword : null,
        user : {
            id : null,
            user : null,
            password : null,
            username: null,
            profile_type : 0,
            full_name : null,
            cpf : null,
            email: null,
            gender : null,
            birth_date : null,
            mobile : null,
            phone : null,

          
            zip  : null,
            city  : null,
            state  : null,
            district : null,
            street  : null,
            number  : null,
            complement : null,

            email_marketing  : false,
            privacy_terms  : false,
            cpf_nf : false

        }


    };
  },
  mounted() {
      this.getUser();
      this.updatePassword = this.$store.getters.loggedIn ? false : true;
  },
  /*
  computed: {
    currentSignUpStep: {
      get: function () {
        return this.$store.getters["getCurrentSignUpStep"];
      },
      set: function (newVal) {
        this.$store.commit("setCurrentSignUpStep", newVal);
      },
    },
    formData: {
      get: function () {
        return this.$store.getters["getFormData"];
      },
      set: function () {
        this.$store.commit("setFormData", this.formData);
      },
    },
  },
  */
  methods: {
  updatePasswordOnChange() {
      if (!this.updatePassword) {
        this.user.password = null;
        this.checkPassword = null;
        this.passwordErrors = [];
      }
  },
  getUser() {
      if(this.$store.getters.loggedIn) {

        getAPI.get('/api/get-user/')
          .then((response) => {
            this.user = response.data;
            this.user.cpf = this.user.cpf + ''
            this.user.cpf = this.user.cpf.padStart(11, '0')

            console.log('this.user.birth_date: ', this.user.birth_date)
            const [ year, month, day  ] = this.user.birth_date.split('-')
            this.user.birth_date = day + '/' + month + '/' + year

          })
          .catch(err => {
            this.alertMessage = 'Não foi possivel recuperar as informações do usuário. Por favor contate o atendimento online';
            this.alertInvalidProfile = true;
            console.log('profile exception: ', err)
          })

        
      }
  },

  passwordOnChange(password, passwordConfirmation) {
    this.passwordErrors = [];
    if(password && passwordConfirmation) {
      if(password !== passwordConfirmation) {
        this.passwordErrors.push('Senhas não conferem')
      }
    }
  } , 
  getMonthDiff(date1, date2) {
    // Calculate the difference in years and months
    const yearsDiff = date2.getUTCFullYear() - date1.getUTCFullYear();
    const monthsDiff = date2.getUTCMonth() - date1.getUTCMonth();

    // Convert the difference to months
    const totalMonthsDiff = (yearsDiff * 12) + monthsDiff;

    return totalMonthsDiff;
  },

  birthDateOnChange(birthDate) {

      this.birthDateErrors=[];
      if (!birthDate) {
          this.birthDateErrors.push('Campo necessário');
          return;
      } 

      const [ day, month, year ] = birthDate.split('/')
      const validBirthDate = new Date(year+'-' + month + '-'+ day);
      console.log('validBirthDate:', validBirthDate.toISOString().slice(0,10), ' original: ',year+'-' + month + '-'+ day )
      if(!validBirthDate || validBirthDate.toISOString().slice(0,10) !== year+'-' + month + '-'+ day) {
          this.birthDateErrors.push('Data inválida');
          return;
      }
  
      const today = new Date() //.toISOString().split('T')[0]
      const months = this.getMonthDiff(validBirthDate, today)

      if (months/12<18) {
          this.birthDateErrors.push('É necessário ser maior de 18 anos para se cadastrar ');
          return;

      }
  },
   

   
    validateCpf() {
          let cpf = this.user.cpf.replace(/\D+/g, '')
          console.log(cpf)
          this.cpfErrors = []
          if(checkCpf(cpf)) {
            
            let data = {cpf: cpf}

            getAPI.post('/api/check-cpf/', data)
            .then(response => {
              // {'cpf-exists': True}
              if( response.data['cpf-exists']) {
                  this.cpfErrors = ['CPF já cadastro. Utilize o link esqueci minha senha']
              }
            })
            .catch(err => {
              console.log('checkCpf() exception:', err)
            })
          }  
          else {
            this.cpfErrors.push('CPF inválido')
          }
        
    },

    getZipAddress() {
        if(!this.user.zip) {
          return
        }
        axios
          .get(`https://opencep.com/v1/${this.user.zip.replace(/\D+/g, '')}`)
          .then((response) => {
              
              // using stringify to beautify the output
              //this.res = JSON.stringify(response.data);

            this.user.city = response.data.localidade;
            this.user.state = response.data.uf;
            this.user.street = response.data.logradouro;
            this.user.district = response.data.bairro;

          })
          .catch((errors) => {
              console.log('opencep() exception: ',errors); // Errors
          });
        /*
        getAPI.get(`https://opencep.com/v1/${this.user.zip}`)
          .then(response => {
            / *
            {
              "cep": "80630-060",
              "logradouro": "Avenida da República",
              "complemento": "de 4981/4982 a 7288/7289",
              "bairro": "Guaíra",
              "localidade": "Curitiba",
              "uf": "PR",
              "ibge": "4106902"
            }
            * /            
            this.user.city = response.data.localidade;
            this.user.state = response.data.uf;
            this.user.street = response.data.logradouro;
            this.user.district = response.data.bairro;
          })
          .catch(err => {
            console.log('getZip() exception:', err)
          })
          */
    },
    saveFormData() {
      if (!this.validProfile){
        this.alertMessage = 'É necessário preencher corretamente as informações';
        this.alertInvalidProfile = true;
        return;
      }

      if(!this.user.privacy_terms){
        this.alertMessage = 'É necessário ler e aceitar os termos de uso de privacidade';
        this.alertInvalidProfile = true;
        return;

      }

      let userData = JSON.parse( JSON.stringify(this.user))

      
      userData.cpf = this.user.cpf.replace(/\D+/g, '')
      userData.zip = this.user.zip.replace(/\D+/g, '')

      const [ day, month, year ] = this.user.birth_date.split('/')
      userData.birth_date = year+'-' + month + '-'+ day

      if (userData.id) {
          getAPI.put('/api/edit-user/', userData)
            .then(() => {
              this.alertMessage = 'Dados salvos com sucesso';
              this.alertInvalidProfile = true;
            })
            .catch(err => {
              this.alertMessage = 'Não foi possivel salvar as informações. Por favor entre em contato com nosso atendimento';
              this.alertInvalidProfile = true;
              console.log('profile exception: ', err)
            })
      }
      else{
          getAPI.post('/api/add-user/', userData)
            .then(() => {
              this.alertMessage = 'Cadastro registrado com sucesso';
              this.alertInvalidProfile = true;
            })
            .catch(err => {
              this.alertMessage = 'Não foi possivel salvar as informações. Por favor entre em contato com nosso atendimento';
              this.alertInvalidProfile = true;
              console.log('profile exception: ', err)
            })
      }
//      this.$store.commit("setFormData", this.formData);
//      this.$router.push("/profile");
    },
  },
};
</script>
<style scoped>
.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}
.team-img {
  width: 50%;
}

.container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}


.inputNumberNoSpin >>> input[type="number"] {
  -moz-appearance: textfield;
}
.inputNumberNoSpin >>> input::-webkit-outer-spin-button,
.inputNumberNoSpin >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}


.input-upper-case input{
  text-transform: uppercase
}

</style>
