import { render, staticRenderFns } from "./TrabalheConosco.vue?vue&type=template&id=b8a43450&scoped=true&"
var script = {}
import style0 from "./TrabalheConosco.vue?vue&type=style&index=0&id=b8a43450&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8a43450",
  null
  
)

export default component.exports