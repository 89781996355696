<template>
  <div class="checkProject">
   <v-app id="inspire">
      <v-main>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                  <v-card class="elevation-12">
                     <v-toolbar dark color="primary">
                        <img class=" mr-2" :src="require('../../public/favicon.png')" height="40"/>
                        <v-toolbar-title>Bem vindo</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <v-form v-on:keyup.enter.native="xlogin">
                           <v-text-field
                              id="id"
                              prepend-icon="mdi-lock"
                              name="projectId"
                              label="Id"
                              type="text"
                              v-model="projectId"
                              :error-messages="error_list"
                           ></v-text-field>
                        </v-form>
                     </v-card-text>
                     <v-card-actions>
                        <!-- <p><a href="http://127.0.0.1:8000/api/password_reset/" target="_blank" style ="margin-left:  10px;">Esqueceu a senha?</a></p> -->
                             
                        
     
                        <v-spacer></v-spacer>
                        <v-btn color="primary" :loading="loading" :disabled="loading" @click="xlogin()">Ok</v-btn>
                     </v-card-actions>
                  </v-card>
               </v-flex>
            </v-layout>
         </v-container>
      </v-main>
   </v-app>


   
   
</div>
</template>

<script>
import { getAPI } from '../../axios-api'
export default {
   name: 'Login',
   props: {
      source: String,
   },

    data() {
        return {
            error_list : [],
            loading: false,
            projectId : null                
        }
    },   
   created: function () {
        //console.log('login()');
        this.$store.dispatch('loader', false)
    }, 

   methods: {
        xlogin() {
           this.loading = true;

            let data = {projectId: this.projectId}

            getAPI.post('/api/check-project-id/', data)
            .then(response => {

              if( response.data['project-exists']) {
                  this.$store.dispatch( 'setProjectAsValid')
                  .then( () => {
                     this.$router.push({ name: 'Home' })
                  })
                  .catch( err => {
                     this.loading = false;
                     this.error_list.push('Problemas localizando projeto')
                     console.log('checkProject exception 1', err)
                  })               

              }
              else {
               this.loading = false;
               
               this.error_list.push('Id não localizado')

              }

            })
            .catch(err => {
               this.loading = false;
               
               this.error_list.push('Problemas localizando projeto')
               console.log('checkProject() exception:', err)
            })

        }

    },
};
</script>

<style></style>
