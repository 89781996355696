<template>
  <div>
    <v-container>
      <div class="row">
        <div
         class="hidden-sm-and-down col-md-3 col-sm-3 col-xs-12"
        >
          <v-card outlined>
            <v-card-title>Filtros</v-card-title>
            <v-divider></v-divider>
            <template>
              <v-treeview :items="items" :open="[]" :active="[]" :selected-color="'#fff'" activatable open-on-click dense></v-treeview>
            </template>
            <v-divider></v-divider>
            <v-card-title>Preço</v-card-title>
            <v-range-slider
              v-model="range"
              :max="max"
              :min="min"
              :height="10"
              class="align-center"
              dense
            ></v-range-slider>
            <v-row class="pa-2" dense>
              <v-col cols="12" sm="5">
                <v-text-field
                  :value="range[0]"
                  label="Mín"
                  outlined
                  dense
                  @change="$set(range, 0, $event)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <p class="pt-2 text-center">A</p>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  :value="range[1]"
                  label="Máx"
                  outlined
                  dense
                  @change="$set(range, 1, $event)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-title class="pb-0">Popularidade</v-card-title>
            <v-container class="pt-0"  fluid>
              <v-checkbox append-icon="mdi-star" label="4 ou mais" hide-details dense></v-checkbox>
              <v-checkbox append-icon="mdi-star" label="3 ou mais" hide-details dense></v-checkbox>
              <v-checkbox append-icon="mdi-star" label="2 ou mais" hide-details dense></v-checkbox>
              <v-checkbox append-icon="mdi-star" label="1 ou mais" hide-details dense></v-checkbox>
            </v-container>
            <v-divider></v-divider>
            <v-card-title class="pb-0">Marca</v-card-title>
            <v-container class="pt-0" fluid>
              <v-checkbox  label="Marca A" hide-details dense></v-checkbox>
              <v-checkbox  label="Marca B" hide-details dense></v-checkbox>
              <v-checkbox  label="Marca C" hide-details dense></v-checkbox>
              <v-checkbox  label="Marca D" hide-details dense></v-checkbox>
              <v-checkbox  label="Marca E" hide-details dense></v-checkbox>
              <v-checkbox  label="Marca F" hide-details dense></v-checkbox>
              <v-checkbox  label="Marca G" hide-details dense></v-checkbox>
            </v-container>

          </v-card>
        </div>
        <div
          class="col-md-9 col-sm-9 col-xs-12"
        >

          <v-breadcrumbs class="pb-3" :items="breadcrums"></v-breadcrumbs>

          <v-row dense>
           <!--  <v-col cols="12" sm="8" class="pl-6 pt-6">
              <small>Showing 1-12 of 200 products</small>
            </v-col> -->
            <v-col cols="12" sm="4">
              <v-select class="pa-0" v-model="select" :items="options" style="margin-bottom: -20px;" outlined dense></v-select>
            </v-col>
            <v-spacer> </v-spacer>
            <v-col cols="12" sm="6" class="pl-15" >
              <div >
                
                <v-pagination
                  v-model="page"
                  :length="productListLength"
                  :total-visible="6"           
                  @input="handlePageChange"   
                  color = "#78909C"
                  
                ></v-pagination>
              </div>

            </v-col>

          </v-row>

          <!-- <v-divider></v-divider> -->

          <div class="row text-center">
            <div class="col-md-3 col-sm-6 col-xs-12" :key="pro.id" v-for="pro in products">
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="mx-auto"
                  color="white"
                  max-width="600"
                  height="100%"
                >
                <!-- :width="$vuetify.breakpoint.xsOnly ? '150px' : '100px'" -->
                  <v-img
                    class="white--text align-end mx-auto"
                    :aspect-ratio="9/16"
                    :height="$vuetify.breakpoint.xsOnly ? '350px' : '200px'"
                    
                    v-bind:src="pro.src"
                    @error="pro.src = pro.src_default"
                  >
                    <!-- <v-card-title>{{pro.type}} </v-card-title> -->
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out white darken-6 v-card--reveal display-3 white--text"
                        style="height: 100%;"
                      >
                        <v-btn v-if="hover" :to="`/product/${pro.id}`" class="" outlined>Detalhes</v-btn>
                      </div>

                    </v-expand-transition>
                  </v-img>
                  <v-card-text pb-3>
                    <div><a :to="`/product/${pro.id}`" style="text-decoration: none" >{{pro.name|truncate(20)}}</a></div>
                    
                    
                  </v-card-text>
                  <v-spacer></v-spacer>
                   <v-card-actions>
                  <v-card-text class="bolded">
                    <div> R${{pro.price}}</div>
                  </v-card-text>
                  </v-card-actions>
                </v-card>
              </v-hover>
            </div>
          </div>
            <v-row dense>
            <v-col cols="12" sm="6" class="pl-6 pt-6">
              <small>Mostrando {{(page*itemsPerPage) -itemsPerPage +1}}-{{itemsPerPage*page}} de {{ itemsPerPage*productListLength }} itens</small>
            </v-col>
            <v-spacer> </v-spacer> 
            <v-col cols="12" sm="6" class="pt-6  pl-15">
              <div >
                
                <v-pagination
                  v-model="page"
                  :length="productListLength"
                  :total-visible="6"           
                  @input="handlePageChange"  
                  color = "#78909C" 
                  @update ="window.scrollTo(0,0);"
                ></v-pagination>
              </div>

            </v-col>
          </v-row>

          
        </div>
      </div>
    </v-container>
    <br>  
  </div>
</template>

<script>
    import { getAPI } from '../../axios-api'
    export default {
        data: () => ({
            range: [0, 10000],
            select:'Popularidade',
            options: [
                'Default',
                'Popularidade',
                'Relevância',
                'Preço: Menor para Maior',
                'Preço: Maior para Menor',
            ],
            page:1,
            itemsPerPage : 12,
            productListLength  : 0,
            breadcrums: [],
            /*
                {
                    text: 'Home',
                    disabled: false,
                    href: 'breadcrumbs_home',
                },
                {
                    text: 'Clothing',
                    disabled: false,
                    href: 'breadcrumbs_clothing',
                },
                {
                    text: 'T-Shirts',
                    disabled: true,
                    href: 'breadcrumbs_shirts',
                },
            ],*/
            min:0,
            max:10000,
            items: [],
            /*
                {
                    id: 2,
                    name: 'Shoes',
                    children: [
                        { id: 2, name: 'Casuals' },
                        { id: 3, name: 'Formals' },
                        { id: 4, name: 'Sneakers' },
                    ],
                },
                {
                    id: 1,
                    name: 'Clothing',
                    children: [
                        { id: 5, name: 'Shirts' },
                        { id: 6, name: 'Tops' },
                        { id: 7, name: 'Tunics' },
                        { id: 8, name: 'Bodysuit' },
                    ],
                }
            ],*/
            products:[],
              /*
                {
                    id:1,
                    name:'BLACK TEE',
                    type:'Jackets',
                    price:'18.00',
                    src:require('../assets/img/shop/1.jpg')
                },
              */
        }),
        created: function () {
            //console.log('this.$route.params:', this.$route.params)
            //console.log('this.$store.getters.productSearch.description:', this.$store.getters.productSearch.description)

            let searchQuery = ''

            if (this.$store.getters.productSearch?.category?.tree) {
                searchQuery = `category=${this.$store.getters.productSearch.category.tree}`;

                this.makeBreadCrums(this.$store.getters.productSearch.category.tree)  
                                
            } 
            else if (this.$store.getters.productSearch.description) {
                searchQuery = `description=${this.$store.getters.productSearch.description}`;
                this.makeBreadCrums(`Buscar/${this.$store.getters.productSearch.description}`)
     
            }
            let page = 1;
            this.getProducts(page, searchQuery);
            //this.getHierarchyFromStringList();
            this.getCategories();

            
        }, 
        watch: {
            '$store.getters.productSearch.category.tree':  {
                handler(newVal /*, oldVal*/) {    
                    if(newVal) {
                      // this.$store.getters.productSearch.category.tree
                      this.makeBreadCrums(newVal)
                      let searchQuery = `category=${newVal}`
                      let page = 1
                      this.getProducts(page, searchQuery);
                      window.scrollTo(0,0);
                    }
                    
                },
                deep: true
            },

            '$store.getters.productSearch.description':  {
                handler(newVal /*, oldVal*/) {    
                    if(newVal) {
                      // this.$store.getters.productSearch.description
                      this.makeBreadCrums(`Buscar/${newVal}`)
                      let searchQuery = `description=${newVal}`
                      let page = 1
                      this.getProducts(page, searchQuery);
                      
                    }
                    
                },
                deep: true
            }            
        },        

        methods: {
            makeBreadCrums(categoryTree)  {
              this.breadcrums = []
              let category = categoryTree.split('/')
              category.forEach( (item) => {
                  let breadcrum = {
                    text: item,
                    disabled: true,
                    href: 'breadcrumbs_home',                                          
                  }
                  this.breadcrums.push(breadcrum);
              });                

            },
            getCategories() {
                getAPI.get(`/api/categorias/`)
                .then(response => {
                  /*
                  {
                          "id": 1,
                          "descricao": "Beleza/Acessório feminino/Brinco",
                          "descricao_ascii": "beleza/acessorio-feminino/brinco"
                      },
                  */

                  // console.log('response.data:', response.data);
                  this.items = [];
                  let self = this;
                  let vars = {id: 0};
                  response.data.forEach( (item) => {
                    let strKeys = item.descricao
                    this.makeChildren(strKeys.split('/'), this.items, vars)
                  }, self) ;

                  console.log('this.items:', this.items)
                  


                })
                .catch(err => {
                    console.log('shop.getCategories() exception: ', err)
                })

            },
            getProducts(page=1, searchQuery) {
                let parameters = `page=${page}`
                if(searchQuery) {
                    parameters = parameters + '&' + searchQuery
                }
                // &description=${category}
                getAPI.get(`/api/produtos/?${parameters}`)
                .then(response => {
                  
                  this.productListLength = Math.ceil(response.data.count / this.itemsPerPage)
                  this.page = page
                  /*
                  {
                      id:12,
                      name:'COATS',
                      type:'Jackets',
                      price:'25.00',
                      src:require('../assets/img/shop/12.jpg')
                  }
                  "id": 18,
                  "descricao": "Piemonte 4mg 30 Sachês",
                  "preco": "62.87",
                  "preco_com_desconto": "62.87",
                  "percentual_desconto": "-20.000",
                  "imagem": "caixa-nissei.jpg",
                  "controlado": false,
                  "categoria": 176                  
                  */
                  this.products = [];
                  // ../assets/img/shop/ 
                  let srcDefault = `/img/med-default-tarja-vermelha_200x200.jpg`// require(`../assets/img/shop/placeholder-med-tarja-vermelha_200x200.jpg`);  
                  
                  response.data.results.forEach(product => {
                      let imagePath

                      try {
                        imagePath = `/img/${product.imagem}` //require(`../assets/img/shop/${product.imagem}`);  
                      } catch (error) {

                        imagePath = srcDefault //require(`../assets/img/shop/placeholder-med-tarja-vermelha_200x200.jpg`);  
                        
                        
                      }
                      
                      this.products.push(
                      {
                          id:product.id,
                          name:product.descricao,
                          type:'Medicamento',
                          price:product.preco,
                          src: imagePath,
                          src_default: srcDefault
                      }



                      )
                  });
                  


                })
                .catch(err => {
                    console.log('shop.getProducts() exception: ', err)
                })

            },
            handlePageChange(page) {
                let searchQuery = ''

                if (this.$store.getters.productSearch?.category?.tree) {
                    searchQuery = `category=${this.$store.getters.productSearch.category.tree}`;
                } 
                else if (this.$store.getters.productSearch.description) {
                    searchQuery = `description=${this.$store.getters.productSearch.description}`;
                }

                this.getProducts(page, searchQuery);
                
                
            },  
            imageDefault(event, img) {
              console.log('imageDefault()')
              console.log('event:', event)
              
              try {
                event.target.src=img  
              } catch (error) {
                console.log('imageDefault() error:', error)
              }
              
            },
            makeChildren(keys, parent, vars) {
              let key = ''
              if(keys.length) {
                    key = keys[0];
                    keys.shift();

                    let newChild = true;
                    let child = parent.find( (current) => {

                        if (current.name === key) {
                          newChild = false
                            return true
                        }
                    })
                
                    if (newChild) {
                        vars.id++;
                        child = {
                            id: vars.id,
                            name: key,
                            children : []
                        }
                    }

                    this.makeChildren(keys, child.children, vars)
                    if (newChild) {
                      parent.push(child)
                    }

                } // if(keys.length)
            },

            getHierarchyFromStringListTest() {
                let categoryList = [
                'Dermocosméticos/Cabelo/Anticaspa',
                'Dermocosméticos/Cabelo/Antiqueda',
                'Dermocosméticos/Cabelo/Tratamento',
                'Dermocosméticos/Corpo/Limpeza',
                'Dermocosméticos/Corpo/Tratamento',
                'Dermocosméticos/Facial/Anti idade',
                'Dermocosméticos/Facial/Hidratante',
                'Dermocosméticos/Facial/Limpeza',
                'Dermocosméticos/Facial/Tratamento',
                'Dermocosméticos/Nutricosméticos/Anti idade',
                'Dermocosméticos/Nutricosméticos/Cabelo e unha',
                'Dermocosméticos/Proteção solar/Corpo',
                'Dermocosméticos/Proteção solar/Facial',
                'Dermocosméticos/Proteção solar/Labial',
                'Higiene pessoal/Banho/Acessório banho',
                'Higiene pessoal/Banho/Sabonete',
                'Higiene pessoal/Barba/Aparelho e lâmina',
                'Higiene pessoal/Barba/Cuidado barba',
                'Higiene pessoal/Barba/Preparo barbear',
                'Higiene pessoal/Cuidado íntimo/Absorvente',
                ]

                let list = [];
                let self = this;
                let vars = {id: 0};
                categoryList.forEach( (item) => {
                  
                  this.makeChildren(item.split('/'), list, vars)
                } , self) ;

                console.log('list:', list)
            },
         
        }, // methods





      filters: {
          truncate: function(value) {
              if (value.length > 53) {
                value = value.substring(0, 50) + '...';
              }
              else if (value.length <= 53) {
                value = (value + ' ' + '\xa0'.repeat(70)).slice(0, 70)
              }
              return value
          }
      },        

    }
</script>
<style scoped>

 .maxSize100pxOrEllipsesTD /deep/   {
 max-width: 10px;
 overflow: hidden;
 text-overflow: ellipsis;
 
}


  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .8;
    position: absolute;
    width: 100%;
  }

.bolded { font-weight: bold; }
</style>
