<template>
  <div>
    <v-form
      v-model="validCredentials"
      action="#"
      :class="{
        'px-2': $vuetify.breakpoint.smAndDown,
        'px-8': $vuetify.breakpoint.mdAndUp,
      }"
      v-on:keyup.enter.native="login"
    >
    
      <!-- <img src="../../assets/img/logo1.svg"  class="team-img pt-8" /> -->
      <h2 class="pb-5 pt-5 font-weight-bold " :style="{ color: 'gray' }">Identificação</h2>
      <v-text-field
        v-model="username"
        prepend-inner-icon="mdi-email"
        label="Email"
        filled
        :rules="emailRules"  
        

      ></v-text-field>
      <v-text-field
        v-model="password"
        prepend-inner-icon="mdi-lock"
        label="Senha"
        type="password"
        filled
        :rules="[(v) => !!v || 'Campo necessário']"
        :error-messages="error_list"
        @change="error_list=[]"
      ></v-text-field>

      <!--tile dark-->
      <v-btn
        color="info"
        block
        
        
        class="pa-6 font-weight-bold"
        elevation="0"
        @click="login()"
        :loading="loading" :disabled="loading"
        >Entrar</v-btn
      >
      <v-row class="justify-center py-10">
        <span
          :class="{
            'text-secondary forgot-password-sm': $vuetify.breakpoint.smAndDown,
            'text-secondary forgot-password-md': $vuetify.breakpoint.mdAndUp,
          }"
          >Esqueceu a senha?</span
        >
      </v-row>
    </v-form>

   <v-snackbar
      v-model="alertInvalidCredentials"
    >
      É necessário preencher corretamente as credenciais

      <template v-slot:action="{ attrs }">
        <v-btn
          color="error"
          text
          v-bind="attrs"
          @click="alertInvalidCredentials = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>      
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      username: null,
      emailRules: [
        v => !!v || 'é necessário informar o email',
        v => /.+@.+\..+/.test(v) || 'Email precisa ser válido',
      ],

      password: null,
      error_list : [],
      validCredentials : false,
      alertInvalidCredentials : false,
      loading: false,
    };
  },
    created: function () {
      //console.log('login()');
      this.$store.dispatch('loader', false)
  }, 
  methods: {
    login() {

      if (!this.validCredentials) {
        this.alertInvalidCredentials = true;
        return;
      }
      this.loading = true;
      this.$store.dispatch( 'userLogin', {
          username: this.username,
          password: this.password
      })
      .then( () => {
          this.$store.dispatch( 'userNick')
          .then( () => {
            console.log('logged:', this.$store.getters.userNick)
          })

          this.$router.push({ name: 'Home' })

      })
      .catch( err => {
          this.loading = false;
          this.error_list.push('Credenciais inválidas')
          console.log('login exception 2', err)
      })
      /*
        this.$store.dispatch("loginAndSaveToken", {
          username: this.username,
          asswor: this.password,
        });
      */
      },
  },
};
</script>
<style scoped>
.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}
/*
.team-img {
  width: 50%;
}
*/
.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}
/*
.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}*/
.forgot-password-sm {
  font-size: 12px;
}
.forgot-password-md {
  font-size: 15px;
}
</style>